exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-educations-js": () => import("./../../../src/pages/about/educations.js" /* webpackChunkName: "component---src-pages-about-educations-js" */),
  "component---src-pages-about-experiences-js": () => import("./../../../src/pages/about/experiences.js" /* webpackChunkName: "component---src-pages-about-experiences-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-knowledges-js": () => import("./../../../src/pages/about/knowledges.js" /* webpackChunkName: "component---src-pages-about-knowledges-js" */),
  "component---src-pages-about-skills-js": () => import("./../../../src/pages/about/skills.js" /* webpackChunkName: "component---src-pages-about-skills-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

